import React, { useState } from 'react';
import css from 'src/theme/css';
import ModuleWrapper from 'src/components/ModuleWrapper';
import { graphql } from 'gatsby';
import RichText from 'src/components/RichText';
import TextBlock from 'src/components/TextBlock';
import { Grid, Flex, Box } from 'src/components/FlexBox';
import AppIntegration from './AppIntegration';
import Link from './Link';
import IconPreset from './IconPreset';
import ProductFolder from './ProductFolder';
import Article from './Article';
import SmartButton from 'src/components/SmartButton';
import get from 'lodash/get';
import { useResponsiveValue } from 'src/components/Breakpoints';

const alignmentLookup = {
  left: 'start',
  center: 'center',
};

const maxWidthLookup = {
  2: '80rem',
  3: '90rem',
};

const cellLookup = {
  ContentfulLink: Link,
  ContentfulIconPreset: IconPreset,
  ContentfulProductFolder: ProductFolder,
  ContentfulAppIntegration: AppIntegration,
  ContentfulTextBlock: (props) => (
    <TextBlock size="s" css={css({ py: 4, margin: '0 auto' })} {...props} />
  ),
  ContentfulArticle: Article,
};

export default ({
  items,
  text,
  alignment,
  columns,
  buttons,
  appIntegrationsHaveDescription,
  maxRows,
  shouldCollapseMargin,
  ...other
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const maxItems = maxRows
    ? useResponsiveValue([1, 2, 3, 4, 5].map((n) => Math.min(n, columns))) *
      maxRows
    : items.length;

  const hasArticles = items[0].__typename === 'ContentfulArticle';

  return (
    <ModuleWrapper {...other}>
      <Box
        flexDirection="column"
        alignItems={alignmentLookup[alignment]}
        textAlign={alignmentLookup[alignment]}
        css={css({
          px: 'pageMargin',
          pt: shouldCollapseMargin ? 0 : 'sectionMargin',
          pb: 'sectionMargin',
          width: '100%',
          mx: 'auto',
          maxWidth: hasArticles ? 'none' : maxWidthLookup[columns] || 'none',
        })}
      >
        {text && (
          <RichText
            {...text}
            size="m"
            alignItems="center"
            css={css({ mb: 5 })}
          />
        )}
        <Grid
          alignSelf="stretch"
          alignItems="start"
          justifyItems="center"
          justifyContent="center"
          gridTemplateColumns={[1, 2, 3, 4, 5].map(
            (n) => `repeat(${Math.min(n, columns)}, 1fr)`
          )}
          gridGap={hasArticles ? 4 : 3}
        >
          {items.slice(0, isOpen ? undefined : maxItems).map((item) => {
            const Cell = get(cellLookup, item.__typename, () => <></>);
            return (
              <Cell
                key={item.id}
                {...item}
                hasDescription={
                  appIntegrationsHaveDescription == null
                    ? true
                    : appIntegrationsHaveDescription
                }
              />
            );
          })}
        </Grid>
        {!isOpen && items.length > maxItems ? (
          <SmartButton
            variant="Arrow"
            label="See more"
            onClick={() => void setIsOpen(true)}
            css={css({ mt: 5 })}
          />
        ) : (
          buttons instanceof Array && (
            <Flex gx={5} gy={4} justifyContent="center" css={css({ mt: 5 })}>
              {buttons.map((button) => (
                <SmartButton key={button.id} {...button} />
              ))}
            </Flex>
          )
        )}
      </Box>
    </ModuleWrapper>
  );
};

export const query = graphql`
  fragment GridModuleFragment on ContentfulGridModule {
    id
    slug
    colorMode
    alignment
    columns
    maxRows
    appIntegrationsHaveDescription
    buttons {
      ...ButtonFragment
    }
    text {
      json
    }
    background {
      ...ImageGroupFragment
    }
    items {
      ... on ContentfulTextBlock {
        ...TextBlockFragment
      }
      ... on ContentfulAppIntegration {
        ...GridModuleAppIntegrationFragment
      }
      ... on ContentfulIconPreset {
        identifier
        media {
          file {
            url
          }
        }
        name
        url
      }
      ... on ContentfulProductFolder {
        title
        url
        additionalItemCount
        primaryImage {
          fluid(maxWidth: 500, quality: 90) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        secondaryImage {
          fluid(maxWidth: 500, quality: 90) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
      ... on ContentfulArticle {
        ...GridModuleArticleFragment
      }
    }
  }
`;
