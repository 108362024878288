import React from 'react';
import { graphql } from 'gatsby';
import css from 'src/theme/css';
import ColorProvider from 'src/components/ColorProvider';
import Frame from 'src/components/Frame';
import CircleIcon, * as circleIcon from 'src/components/CircleIcon';
import Text from 'src/components/Text';
import SmartButton from 'src/components/SmartButton';
import BackgroundImg from 'gatsby-background-image';
import { Grid } from 'src/components/FlexBox';
import Icon from 'src/components/IconPreset';

export default ({
  slug,
  title,
  subtitle,
  videoUrl,
  brand,
  previewImage,
  showBrand,
  ...other
}) => {
  const previewImageStack = [
    'linear-gradient(rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0) 50%, rgb(0, 0, 0) 100%)',
    previewImage.fluid,
  ];

  return (
    <ColorProvider mode="dark" {...other}>
      <BackgroundImg
        fluid={previewImageStack}
        css={css({
          my: [2, 3],
          alignSelf: 'stretch',
          width: '100%',
          borderRadius: 2,
          overflow: 'hidden',
          transition: 'transform 1.2s cubic-bezier(0.19, 1, 0.22, 1)',
          transform: 'scale(1)',
          cursor: 'pointer',
          willChange: 'transform',
          '::before, ::after': {
            transform: 'scale(1.1)',
            transition:
              'transform 1.2s cubic-bezier(0.19, 1, 0.22, 1) !important',
          },
          'body:not(.touch) &:hover': {
            ...circleIcon.hoverStyles,
            transform: 'scale(1.05)',
            '::before, ::after': {
              transform: 'scale(1)',
            },
          },
        })}
      >
        <SmartButton url={`/${slug}/`} action="Link">
          <Grid
            gridTemplateRows={`${showBrand ? '4rem' : 'auto'} auto auto auto`}
            gridGap={3}
            alignItems="start"
            alignContent="start"
            justifyItems="start"
            flexGrow={1}
            css={css({
              p: 4,
              height: '100%',
            })}
          >
            {showBrand ? (
              <>
                <Icon {...brand} />
                <Frame ratio={2 / 3} css={{ width: '100%' }} />
                <CircleIcon type={videoUrl ? 'play' : 'text'} />
              </>
            ) : (
              <>
                <CircleIcon type={videoUrl ? 'play' : 'text'} />
                <Frame ratio={1} css={{ width: '100%' }} />
              </>
            )}

            <Text textAlign="start" variant="heading.m" as="h3">
              {title}
            </Text>
            <Text textAlign="start" variant="small" as="p">
              {subtitle}
            </Text>
          </Grid>
        </SmartButton>
      </BackgroundImg>
    </ColorProvider>
  );
};

export const query = graphql`
  fragment GridModuleArticleFragment on ContentfulArticle {
    id
    title
    subtitle
    videoUrl
    previewImage {
      fluid(maxWidth: 1000, quality: 80) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    brand {
      identifier
      media {
        file {
          url
        }
      }
    }
    showBrand
    slug
  }
`;
