import React from 'react';
import css from 'src/theme/css';
import Link from 'src/components/Link';
import IconPreset from 'src/components/IconPreset';

export default ({ url, ...other }) => (
  <Link
    url={url}
    css={css({
      display: 'flex',
      justifySelf: 'stretch',
      alignSelf: 'stretch',
      alignItems: 'center',
      justifyContent: 'center',
      bg: 'modeAlpha.1',
      borderRadius: 2,
      p: 4,
      transition: 'background 200ms ease',
      ':hover': { bg: 'modeAlpha.3' },
    })}
  >
    <IconPreset {...other} />
  </Link>
);
