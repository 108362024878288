import React from 'react';
import Text from 'src/components/Text';
import TextStack from 'src/components/TextStack';
import Link from 'src/components/Link';
import css from 'src/theme/css';

export default ({ label, description, url }) => (
  <Link
    url={url}
    css={css({
      border: 'solid 1px',
      borderRadius: 2,
      borderColor: 'modeAlpha.3',
      justifySelf: 'stretch',
      textAlign: 'start',
      p: 4,
      transition: 'background 200ms ease',
      ':hover': {
        bg: 'modeAlpha.1',
      },
    })}
  >
    <TextStack>
      <Text variant="heading.m">{label}</Text>
      <Text>{description.description}</Text>
    </TextStack>
  </Link>
);
