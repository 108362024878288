import { graphql } from 'gatsby';
import React, { useContext } from 'react';
import css from 'src/theme/css';
import { Flex, Box } from 'src/components/FlexBox';
import Text from 'src/components/Text';
import Link from 'src/components/Link';
import SmartButton from 'src/components/SmartButton';
import Img from 'gatsby-image';
import { ThemeContext } from 'styled-components';
import CircleIcon, * as circleIcon from 'src/components/CircleIcon';

export default ({
  slug,
  logo,
  title,
  integrationDescription,
  hasIntegrationPage,
  externalLink,
  hasDescription = true,
}) => {
  const { isDark } = useContext(ThemeContext);
  const hasNoLink = !hasIntegrationPage && !externalLink;
  return (
    <Link
      isDisabled={hasNoLink}
      isBlank={!hasIntegrationPage}
      url={hasIntegrationPage ? `/integrations/${slug}` : externalLink}
      css={css({
        position: 'relative',
        display: 'flex',
        alignItems: 'stretch',
        alignSelf: 'stretch',
        justifySelf: 'stretch',
        cursor: hasNoLink ? 'default' : '',
        background: ({ colors }) =>
          isDark
            ? `linear-gradient(to bottom, ${colors.black}, ${colors.gray800})`
            : `linear-gradient(to bottom, ${colors.gray100}, ${colors.white})`,
        borderRadius: 2,
        border: 'solid 1px',
        borderColor: isDark ? 'gray800' : 'gray100',
        overflow: 'hidden',
        transform: 'translate3d(0, 0, 0)',
        [hasNoLink ? '' : '&:hover > *']: {
          opacity: 1,
        },
        '&:hover': circleIcon.hoverStyles,
      })}
    >
      <Box
        css={css({
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          opacity: 0,
          backfaceVisibility: 'hidden',
          bg: isDark ? 'sunset' : 'sunrise',
          transition: 'opacity 200ms ease',
        })}
      />
      {hasDescription ? (
        <Flex
          alignItems="start"
          flexGrow={1}
          gx={3}
          css={css({
            position: 'relative',
            p: 4,
          })}
        >
          <Img {...logo} css={css({ flexShrink: 0, width: 5 })} />
          <Flex flexDirection="column" textAlign="start" gy={2}>
            <Text variant="heading.s">{title}</Text>
            <Text variant="small">
              {integrationDescription.childMarkdownRemark.rawMarkdownBody}
            </Text>
          </Flex>
        </Flex>
      ) : (
        <Flex
          alignItems="center"
          flexGrow={1}
          justifyContent="space-between"
          gx={3}
          css={css({ p: 4, position: 'relative' })}
        >
          <Img {...logo} css={css({ flexShrink: 0, width: 5 })} />
          <Text variant="heading.s" textAlign="start">
            {title}
          </Text>
          <Box flexGrow={1} />
          <SmartButton
            css={css({
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              ml: 'auto',
              flexShrink: 0,
              '> * + *': {
                ml: 2,
              },
            })}
          >
            <Text variant="caps" css={css({ mt: 1 })}>
              Learn more
            </Text>
            <CircleIcon type="arrow" isSmall />
          </SmartButton>
        </Flex>
      )}
    </Link>
  );
};

export const query = graphql`
  fragment GridModuleAppIntegrationFragment on ContentfulAppIntegration {
    id
    slug
    title
    category
    externalLink
    hasIntegrationPage
    logo {
      fluid(maxWidth: 200, quality: 80) {
        ...GatsbyContentfulFluid_noBase64
      }
    }
    integrationDescription {
      childMarkdownRemark {
        rawMarkdownBody
      }
    }
  }
`;
