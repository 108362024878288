import React from 'react';
import Img from 'gatsby-background-image';
import Frame from 'src/components/Frame';
import { Grid, Box } from 'src/components/FlexBox';
import css from 'src/theme/css';
import Text from 'src/components/Text';
import Link from 'src/components/Link';

export default ({
  primaryImage,
  secondaryImage,
  title,
  url,
  additionalItemCount,
}) => (
  <Link
    url={url}
    css={css({
      boxShadow: 'rgba(0, 0, 0, 0.3) 0 2rem 2rem',
      borderRadius: '16px',
      width: '100%',
      maxWidth: '20rem',
      svg: {
        transition: 'stroke 200ms ease',
        stroke: '#303247',
      },
      ':hover svg': { stroke: 'purpleLight' },
    })}
  >
    <Frame ratio={0.8}>
      <svg
        width="227"
        height="193"
        viewBox="0 0 227 193"
        preserveAspectRatio="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9 1h44.854a10 10 0 0 1 4.219.934l23.92 11.132a10 10 0 0 0 4.22.934H218a8 8 0 0 1 8 8v162a8 8 0 0 1-8 8H9a8 8 0 0 1-8-8V9a8 8 0 0 1 8-8z"
          stroke="inherit"
          strokeWidth="2"
          fill="#303247"
          vectorEffect="non-scaling-stroke"
        ></path>
      </svg>
      <Box css={{ display: 'flex' }}>
        <Grid
          gridTemplateColumns="2fr 1fr"
          gridTemplateRows="1fr 1fr auto"
          gridGap="4px"
          css={css({
            flexGrow: 1,
            pt: 'calc(5.25% + 6px)',
            px: '6px',
            pb: '6px',
          })}
        >
          <Img
            {...primaryImage}
            css={css({
              gridRow: '1 / span 2',
              flexGrow: 1,
              borderRadius: 1,
              overflow: 'hidden',
            })}
          />
          <Img
            {...secondaryImage}
            css={css({
              width: '100%',
              height: '100%',
              borderRadius: 1,
              overflow: 'hidden',
            })}
          />
          <Box
            justifyContent="center"
            alignItems="center"
            css={css({ bg: '#282a3c', borderRadius: 1, overflow: 'hidden' })}
          >
            <Text fontWeight="normal" color="gray400">
              +
            </Text>
            <Text color="white">{additionalItemCount}</Text>
          </Box>
          <Box
            flexDirection="column"
            flexShrink={0}
            css={css({ textAlign: 'start', gridColumn: '1 / span 2', p: 2 })}
          >
            <Text variant="small" color="white">
              {title}
            </Text>
            <Text fontSize={0} color="gray400" fontWeight="normal">
              {additionalItemCount + 2} items
            </Text>
          </Box>
        </Grid>
      </Box>
    </Frame>
  </Link>
);
