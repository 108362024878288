import React from 'react';
import { graphql } from 'gatsby';
import RichText from 'src/components/RichText';

const alignmentLookup = {
  auto: ['center', null, 'start'],
  left: 'start',
  center: 'center',
};

const sizeLookup = {
  normal: null,
  small: '43rem',
};

const TextBlock = ({ content, alignment, size, ...other }) => {
  const align = alignmentLookup[(alignment || 'auto').toLowerCase()];
  const maxWidth = sizeLookup[(size || 'normal').toLowerCase()];

  return (
    <RichText
      textAlign={align}
      alignItems={align}
      justifySelf={align}
      css={{
        maxWidth,
      }}
      {...content}
      {...other}
    />
  );
};

export default TextBlock;

export const query = graphql`
  fragment TextBlockFragment on ContentfulTextBlock {
    id
    alignment
    size
    content {
      json
    }
  }
`;
